.fatherConteiner {
    top: 0;
    position: sticky;
    z-index: 9999;
}

.conteiner {
    display: flex;
    height: 5rem;
    background-color: #222222;
    justify-content: space-between;
    color: rgb(199, 199, 199);
}

.logoConteiner {
    display: flex;
    align-items: center;
    margin-left: 5%;
}

.logoConteiner img {
    width: 90%;
}

.refsConteiner {
    display: flex;
    align-items: center;
    margin-right: 5%;
}

.refsConteiner span {
    margin-left: 2rem;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
}

.refsConteiner span:hover {
    color: white;
}

.conteinerContact {
    position: absolute;
    right: 0;
    top: 4.5rem;
}

.responsiveRefs {
    display: none;
    align-items: center;
    margin-right: 5%;
    font-size: 30px;
}

@media screen and (max-width: 1240px) {
    .refsConteiner {
        display: none;
    }
    .responsiveRefs {
        display: flex;
    }
  }
.conteiner {
    margin-top: 5rem;
    height: 100vh;
    background-color: #303030;
    text-align: center;
}

.tittle {
    color: white;
    font-size: 40px;
}

.carousel {
    height: 70%;
}

@media screen and (max-width: 2000px) {
    .carousel {
        height: 80%;
    }
}


@media screen and (max-width: 1000px) {
    .carousel {
        height: 80%;
    }
    .conteiner {
        height: 80vh;
    }
}

@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');

.conteiner {
    margin: .6rem;
    height: 100%;
    background-color: white;
    box-shadow: 0px 2px 7px 4px rgba(0,0,0,0.75);
    -webkit-box-shadow: 0px 2px 7px 4px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 2px 7px 4px rgba(0,0,0,0.75);
    text-align: center;
}

.conteiner img {
    width: 100%;
    height: 60%;
    border-bottom: 1px solid grey;
}

.conteiner h1 {
    font-family: 'Playfair Display', serif;
}

.conteiner p {
    margin: 2%;
    color: grey;
    font-weight: 300;
}

.infsConteiner {
    height: 80%;
}

.bntConteiner {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 20%;
}

.bntConteiner button {
    padding: .5rem;
    margin-left: 2%;
    margin-right: 2%;
    width: 30%;
    height: 40%;
    border: none;
    color: white;
    font-weight: 300;
    cursor: pointer;
}

.bntGit {
    background-color: black;
}

.bntGit:hover {
    background-color: rgb(41, 41, 41);
}

.bntSite {
    background-color: rgb(174, 0, 255);
}

.bntSite:hover {
    transition: .3s;
    background-color: rgb(128, 0, 187)
}